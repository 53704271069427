<template>
  <div class="sub-sort">
    <div class="sort">
      <a
        href="javascript:"
        :class="{ active: sortParams.sortField === '' }"
        @click="updateSortParams({ sortField: '', sortMethod: 'desc' })"
      >
        默认排序
      </a>

      <a
        href="javascript:"
        @click="
          updateSortParams({
            sortField: 'price',
            sortMethod: sortParams.sortMethod === 'desc' ? 'asc' : 'desc',
          })
        "
      >
        价格排序
        <i
          class="arrow up"
          :class="{
            active:
              sortParams.sortField === 'price' &&
              sortParams.sortMethod === 'asc',
          }"
        ></i>
        <i
          class="arrow down"
          :class="{
            active:
              sortParams.sortField === 'price' &&
              sortParams.sortMethod === 'desc',
          }"
        ></i>
      </a>
    </div>
  </div>
</template>
<script>
import VueEvent from '@/model/event'
export default {
  name: 'SubSort',

  data () {
    return {
      sortParams: {
        sortField: '',
        sortMethod: 'desc',
      },
    }
  },
  methods: {
    updateSortParams (el) {
      this.sortParams.sortField = el.sortField
      if (this.sortParams.sortField === '') {
        VueEvent.emit('subCategorySortMitt', {
          sortField: '',
          sortMethod: 'desc',
        })
        return
      }
      if (
        this.sortParams.sortMethod === 'desc' &&
        this.sortParams.sortField === 'price'
      ) {
        VueEvent.emit('subCategorySortMitt', this.sortParams)
        this.sortParams.sortMethod = 'asc'
      } else {
        VueEvent.emit('subCategorySortMitt', this.sortParams)
        this.sortParams.sortMethod = 'desc'
      }
    },
  },

  destoryed () {
    VueEvent.all.clear()
  },
}
</script>
<style scoped lang="less">
.sub-sort {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f8f8fb;
  .sort {
    display: flex;
    a {
      height: 30px;
      line-height: 28px;
      border: 1px solid #e4e4e4;
      padding: 0 20px;
      // margin-right: 2px;
      color: #999;
      border-radius: 2px;
      position: relative;
      transition: all 0.3s;
      &.active {
        background: @xtxColor;
        border-color: @xtxColor;
        color: #fff;
      }
      .arrow {
        position: absolute;
        border: 5px solid transparent;
        right: 8px;
        &.up {
          top: 3px;
          border-bottom-color: #bbb;
          &.active {
            border-bottom-color: @xtxColor;
          }
        }
        &.down {
          top: 15px;
          border-top-color: #bbb;
          &.active {
            border-top-color: @xtxColor;
          }
        }
      }
    }
  }
  .check {
    .xtx-checkbox {
      margin-left: 20px;
      color: #999;
    }
  }
}
</style>
