<template>
  <!-- 筛选区 -->
  <div v-if="filters" class="sub-filter">
    <div style="margin-bottom: 20px">
      <span style="font-size: 16px; font-weight: 600">全部商品</span>
    </div>
    <div
      v-for="(item, index) in filters.value"
      :key="index"
      class="item"
      style="border: 1px solid #eee"
    >
      <div
        class="head"
        style="background: #f8f8fb; text-align: center; margin-right: 8px"
      >
        {{ item.filterName }}：
      </div>

      <div class="body">
        <a
          v-for="value in item.value"
          :key="value"
          href="javascript:"
          :class="{ active: item.selectedFilterName === value }"
          @click="updateSelectedFilters(item, value)"
        >
          {{ value }}
        </a>
      </div>
    </div>
  </div>
  <div v-else class="sub-filter">
    <XtxSkeleton class="item" width="800px" height="40px" />
    <XtxSkeleton class="item" width="800px" height="40px" />
    <XtxSkeleton class="item" width="600px" height="40px" />
    <XtxSkeleton class="item" width="600px" height="40px" />
    <XtxSkeleton class="item" width="600px" height="40px" />
  </div>
</template>
<script>
import XtxSkeleton from '@/components/library/XtxSkeleton'
import VueEvent from '@/model/event'
import { ref } from 'vue'
export default {
  name: 'SubFilter',
  components: { XtxSkeleton },
  data () {
    return {
      filters: {},
      // 存储用户选择的筛选条件
      selectedFilters: {
        brandId: null,
        attrs: [],
      },
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      let postData = ref({})
      // 搜索跳转
      if (this.$route.query.productName) {
        postData = ref({
          subCategoryId: '',
          productName: this.$route.query.productName,
        })
      }
      // 路径跳转
      if (this.$route.params.id) {
        postData = ref({
          subCategoryId: this.$route.params.id,
          productName: '',
        })
      }
      this.$store
        .dispatch('ProductFilter', postData.value)
        .then((response) => {
          const data = response.result.filters

          // 在其他筛选条件加上 "全部" 选项
          data.forEach((item) => {
            item.value.unshift('全部')
            // 存储用户选择的筛选条件
            item.selectedFilterName = '全部'
          })
          // 存储筛选数据
          this.filters.value = response.result.filters
        })
        .catch(() => {})
    },
    updateSelectedFilters (item, value) {
      // 重置用户选择的筛选条件
      this.selectedFilters.attrs = []
      // 更新用户选择的筛选条件
      this.filters.value.forEach((i) => {
        if (item.filterName === i.filterName) {
          i.selectedFilterName = value
        }
      })

      // 商品列表 筛选栏选项
      VueEvent.emit('productFilter', this.filters.value)
    },
  },

  destoryed () {
    VueEvent.all.clear()
  },
}
</script>
<style scoped lang="less">
// 筛选区
.sub-filter {
  background: #fff;
  padding: 25px;
  .item {
    display: flex;
    line-height: 40px;
    .head {
      width: 80px;
      color: #999;
    }
    .body {
      flex: 1;
      a {
        margin-right: 36px;
        transition: all 0.3s;
        display: inline-block;
        &.active,
        &:hover {
          color: @xtxColor;
        }
      }
    }
  }
}

.xtx-skeleton {
  padding: 10px 0;
}
</style>
