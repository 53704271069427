<template>
  <AppLayout>
    <div class="sub-category">
      <div class="container">
        <XtxBread>
          <XtxBreadItem path="/">首页</XtxBreadItem>
          <XtxBreadItem>商品列表</XtxBreadItem>
        </XtxBread>
        <!-- 商品筛选组件 -->
        <SubFilter />
        <!-- 商品区块-->
        <div class="goods-list">
          <!-- 商品排序 -->
          <SubSort />
          <!-- 商品列表 -->
          <GoodsList v-if="goods" :goods="goods" />
          <!-- 无限列表加载组件 -->
          <XtxInfiniteLoading />
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from '@/components/AppLayout'
import SubFilter from '@/views/category/components/SubFilter'
import SubSort from '@/views/category/components/SubSort'
import GoodsList from '@/views/category/components/GoodsList'
import VueEvent from '@/model/event'
import { ref } from 'vue'

export default {
  name: 'SubCategoryPage',
  components: { GoodsList, SubSort, SubFilter, AppLayout },
  data () {
    return {
      goods: {},
      subCategoryId: '',
    }
  },
  created () {
    this.init()
  },
  mounted () {
    this.myMitt()
    this.subCategorySortMitt()
  },
  methods: {
    init () {
      let postData = ref({})

      // 搜索跳转
      if (this.$route.query.productName) {
        postData = ref({
          subCategoryId: '',
          productName: this.$route.query.productName,
          sendFromCityCode: '',
          page: 1,
          rows: 10,
        })
				this.getData(postData.value)
				return
      }

      // 路径跳转
      if (this.$route.params.id) {
        this.subCategoryId = this.$route.params.id
        postData = ref({
          subCategoryId: this.$route.params.id,
          productName: '',
          sendFromCityCode: '',
          page: 1,
          rows: 10,
        })
      }
      if (typeof this.$route.params.id === 'undefined') {
        // 最新供应查看更多
        postData = ref({
          subCategoryId: '',
          productName: '',
          sendFromCityCode: '',
          page: 1,
          rows: 10,
        })
      }
      this.getData(postData.value)
    },
    getData (postData) {
      // 获取商品列表
      this.$store
        .dispatch('ProductList', postData)
        .then((response) => {
          this.goods = response.result.rows

          if (response.returnCode === '5') {
            // 会面码搜索
            this.$router.push({ path: '/company/' + response.result })
          }
        })
        .catch(() => {})
    },

    myMitt () {
      let sendFromCityCodeSele = ''
      let productNameSele = ''
      // 我的商品 筛选栏选项
      VueEvent.on('productFilter', (value) => {
        value.forEach((item) => {
          if (item.filterName === '发货地') {
            if (item.selectedFilterName === '全部') {
              sendFromCityCodeSele = ''
            } else {
              sendFromCityCodeSele = item.selectedFilterName
            }
          }
          if (item.filterName === '产品名') {
            if (item.selectedFilterName === '全部') {
              productNameSele = ''
            } else {
              productNameSele = item.selectedFilterName
            }
          }
        })
        const postData = ref({
          subCategoryId: this.subCategoryId,
          productName: productNameSele,
          sendFromCityCode: sendFromCityCodeSele,
          page: 1,
          rows: 10,
        })
        // 获取商品列表
        this.$store
          .dispatch('ProductList', postData.value)
          .then((response) => {
            this.goods = response.result.rows
          })
          .catch(() => {})
      })
    },

    subCategorySortMitt () {
      const that = this
      // 我的商品 排序
      VueEvent.on('subCategorySortMitt', (value) => {
        // 默认排序
        if (value.sortField === '') {
          desc()
          return
        }

        if (value.sortField === 'price') {
          if (value.sortMethod === 'desc') {
            desc()
          }
          if (value.sortMethod === 'asc') {
            asc()
          }
        }
      })
      function desc () {
        that.goods.sort(function (a, b) {
          return a.activePrice - b.activePrice
        })
      }
      function asc () {
        that.goods.sort(function (a, b) {
          return b.activePrice - a.activePrice
        })
      }
    },
  },

  destoryed () {
    VueEvent.all.clear()
  },
  setup () {
    return {}
  },
}
</script>

<style scoped lang="less">
.goods-list {
  background: #fff;
  padding: 0 25px;
  margin-top: 25px;
  min-height: 200px;
}
</style>
